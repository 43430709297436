import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon for the close button
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Modal,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import ReactPlayer from 'react-player';

const UpdateDrill = ({
	slots,
	selectedSlotIndex,
	selectedTrainingDrill,
	trainingDrillIndex,
	allTrainingDrills,
	updateDrill,
}) => {
	const [open, setOpen] = useState(false);

	const [oldSlots, setOldSlots] = useState([]);
	const [selectedDrill, setSelectedDrill] = useState([]);
	const [selectedSlotIndexx, setSelectedSlotIndexx] = useState([]);
	const [selectedDrillIndex, setselectedDrillIndex] = useState([]);
	const [trainingDrills, setTrainingDrills] = useState([]);

	console.log('selectedDrill', selectedDrill);
	console.log('selectedSlot', selectedSlotIndexx);
	console.log('selectedDrillIndex', selectedDrillIndex);
	console.log('oldSlots', oldSlots);

	const handleOpenOrNot = async () => {
		setSelectedDrill(selectedTrainingDrill);
		setSelectedSlotIndexx(selectedSlotIndex);
		setselectedDrillIndex(trainingDrillIndex);
		setTrainingDrills(allTrainingDrills);
		setOldSlots(slots);
		setOpen(true);
	};

	const updateDrillSlot = (drill) => {
		var newSlots = oldSlots.map((slot, sIndex) =>
			sIndex === selectedSlotIndexx
				? {
						...slot,
						posts: slot.posts.map((post, pIndex) =>
							pIndex === selectedDrillIndex
								? { ...drill, checked: post.checked }
								: post
						),
				  }
				: slot
		);

		updateDrill(newSlots);
		setOpen(false);
	};

	return (
		<>
			<IconButton
				onClick={() => {
					handleOpenOrNot();
				}}
			>
				<EditIcon />
			</IconButton>

			<Modal
				open={open}
				// onClose={() => {
				// 	setOpen(false);
				// }}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				{trainingDrills && selectedTrainingDrill ? (
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '60vw',
							height: '80vh',
							bgcolor: 'background.paper',
							borderRadius: 2,
							p: 3,
							overflowY: 'auto',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Typography variant='h4' id='modal-modal-title'>
								Select the drill to update
							</Typography>
							<IconButton
								onClick={() => {
									setOpen(false);
								}}
								aria-label='close'
							>
								<CloseIcon />
							</IconButton>
						</Box>
						<Box sx={{ flex: 1, overflowY: 'auto' }}>
							<Grid container sx={6} wrap='wrap'>
								{trainingDrills?.map((drill, ind) => (
									<Box
										onClick={() => updateDrillSlot(drill)}
										sx={{
											border: '1px solid #ccc',
											borderRadius: '8px',
											marginBottom: '10px',
											p: 2,
											width: '100%',
											cursor: 'pointer',
											':hover': {
												backgroundColor: pastelColors[ind],
											},
											// backgroundColor: () => generateRandomLightPastelColor(),
										}}
									>
										{/* Checkbox with Date Heading */}

										{/* Posts Mapped for the Slot */}
										<Stack spacing={2}>
											<Box
												sx={{
													display: 'flex', // Horizontal layout
													alignItems: 'center',
													gap: 2,

													borderRadius: '8px',
													p: 2,
												}}
											>
												{/* Video Thumbnail on the Left */}
												<Box
													sx={{
														// width: '150px',
														height: 'auto',
														flexShrink: 0,
													}}
												>
													<ReactPlayer
														width='100%'
														height='100%'
														controls
														url={drill?.youtube_link}
													/>
												</Box>

												{/* Text Content on the Right */}
												<Stack spacing={1} sx={{ flex: 1 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'space-between',
														}}
													></div>
													<Typography variant='h6'>
														{drill?.name_of_session}
													</Typography>
													<Typography variant='subtitle1'>
														{drill?.duration_of_training_drill}
													</Typography>
													<Typography variant='body2'>
														{drill?.ability_suitable_for_and_comments}
													</Typography>
												</Stack>
											</Box>
										</Stack>
									</Box>
								))}
							</Grid>
						</Box>

						<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
							<LoadingButton
								variant='contained'
								onClick={() => {
									// Handle submit action here
									// sendSession();
								}}
							>
								Submit
							</LoadingButton>
						</Box>
					</Box>
				) : (
					<CircularProgress />
				)}
			</Modal>
		</>
	);
};

export default UpdateDrill;

const pastelColors = [
	'#fcfaf2', // Light Cream
	'#d4f7d2', // Soft Green
	'#f7d3e8', // Pinkish Mauve
	'#e0d1f4', // Lavender
	'#f6e9d8', // Beige
	'#d9e9f4', // Soft Blue
	'#f5f2db', // Pale Yellow
	'#e9ffe4', // Light Mint Green
	'#fbf3d2', // Light Mustard
	'#e0fcd7', // Pastel Green
	'#f5d2f9', // Light Purple
	'#fdd8d4', // Light Coral
	'#d7fbef', // Mint Green
	'#f7f9e5', // Soft Lemon
	'#f4edf4', // Light Lilac
	'#d9f5d9', // Light Green
	'#f8f7f2', // Soft Grey
	'#e9eee9', // Muted Green-Grey
	'#daeee6', // Mint Aqua
	'#dce6f0', // Pale Blue
	'#f3e7dc', // Light Sand
	'#f0f4e6', // Pale Mint
	'#f8e1ea', // Soft Blush Pink
	'#e6dbec', // Lavender Grey
	'#e2ebe3', // Mist Green
	'#d6e4ef', // Light Sky Blue
	'#ede5f9', // Faint Lavender
	'#e5e9e0', // Off-White Green
	'#f5f7e1', // Light Lemon
	'#f1fce0', // Light Chartreuse
	'#f4d4fb', // Pale Purple
	'#f7e3e4', // Soft Rose
	'#e2fbf7', // Icy Aqua
	'#eceae7', // Warm Grey
	'#f6f1f6', // Light Mauve
	'#f2dedd', // Soft Peach
	'#e4f4e8', // Light Teal
	'#f3fbe4', // Pale Lime
	'#fef5f2', // Pale Peach
	'#e4e7f9', // Soft Indigo
	'#d9e4eb', // Light Blue-Grey
	'#efd5e2', // Faint Pink
	'#e5f0fa', // Ice Blue
	'#ecd8e4', // Faded Plum
	'#e5d5f7', // Light Violet
	'#fcf4eb', // Pale Sand
	'#dbeaf7', // Sky Blue
	'#f7f5d9', // Light Wheat
	'#fae5e4', // Blush Pink
	'#fdeedf', // Peach Beige
	'#e8dbd8', // Faded Peach
	'#dce9fc', // Soft Cyan
	'#e8dcd9', // Faded Salmon
	'#f8e8e8', // Light Coral Pink
	'#f2eff2', // Soft Greyish Lilac
	'#f6ece7', // Warm Beige
	'#f0e4e8', // Light Rose Grey
	'#e4fbe7', // Pale Mint
	'#ecdbe4', // Soft Mauve
	'#f3f4f3', // Neutral Grey
	'#dcfede', // Mint Green
	'#fde8f4', // Pale Rose
	'#e5f3e0', // Fresh Green
	'#dbe7ff', // Light Periwinkle
	'#e8fbf2', // Soft Aqua
	'#f6e9fa', // Light Lilac Pink
	'#dcfffe', // Light Cyan
	'#e1f2ed', // Soft Teal
	'#f0e6f9', // Faint Lavender
	'#dee8ec', // Light Greyish Blue
	'#e8d9da', // Faded Blush
	'#d6effc', // Light Ice Blue
	'#f1e6f2', // Light Plum
	'#fdece7', // Warm Pinkish Beige
	'#ece6ec', // Pale Mauve
	'#efdde4', // Faded Pink Beige
	'#ece7dc', // Cream Beige
	'#fcfbfc', // Pale Greyish White
	'#eaeff9', // Soft Blue-White
	'#dffcf6', // Soft Mint Aqua
	'#dae6de', // Light Grey-Green
	'#d9def8', // Faded Periwinkle
	'#d8eef0', // Soft Aqua Grey
];
